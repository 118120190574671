//View
export const PREVIEW = 'preview'

//Venues
export const EXOSPHERE = 'exosphere'

//Environments
export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV || 'development'
export const isProd = APP_ENV === 'prod'

// Default data
export const ANALYTICS_PAGE_LOAD_DATA = {
  brand: 'msg-sphere',
  business_unit: 'entertainment',
  experience: 'msg-sphere-web',
  experience_section: 'global'
}

// Link Modules
export const ANALYTICS_LINK_MODULE_GLOBAL_NAV = 'global-navigation'
export const ANALYTICS_LINK_MODULE_HERO = 'hero'
export const ANALYTICS_LINK_MODULE_FILTER = 'filter'
export const ANALYTICS_LINK_MODULE_VIDEO = 'video'
export const ANALYTICS_LINK_MODULE_CTA = 'cta'
export const ANALYTICS_LINK_MODULE_HEADLINER = 'headliner-announcement'
export const ANALYTICS_LINK_MODULE_LATEST_NEWS = 'latest-news'

//Link Locations
export const ANALYTICS_LINK_LOCATION_HEADER = 'header'
export const ANALYTICS_LINK_LOCATION_BODY = 'body'
export const ANALYTICS_LINK_LOCATION_FOOTER = 'footer'
export const ANALYTICS_LINK_LOCATION_FORM = 'form'

//Link id Types
export const ANALYTICS_LINK_ID_CATEGORY = 'category'
export const ANALYTICS_LINK_ID_LOCATION = 'location'

//GTM Constants
export const CTA_CLICK_EVENT = 'cta_click_event'
export const NAV_DRAWER = 'nav drawer'
export const HOME_PAGE_VIDEO = 'home page video'

export const CLIENT_ID = `${process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID}`
export const HEADER_LOGO_URL = 'https://www.thespherevegas.com/'
export const PASSWORD_REGEX_EXPRESSION = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>;=-_]).{8,64}$'
export const DEFAULT_TIME_OF_DAY_UUID = 'C9FC0BD748E0F18E43A953B3D1CEE8FC'
export const DEFAULT_VANTAGE_POINT_UUID = 'BCF6B56F48FBDB20F5A8FDAC02353BA7'
export const PREVIEW_LOGIN_PATH = '/login'
export const PREVIEW_PATH = '/'

export const MAX_FILE_SIZE_IN_KB = 500 * 1024
export const PREVIEW_PAGE_BREAKPOINT = 1024
export const PREVIEW_PAGE_MAX_WIDTH = 1264
export const PREVIEW_PAGE_TITLE_COL_MAX_WIDTH = 500

export const TYPEKIT_CSS_URL = 'https://use.typekit.net/bbg3yei.css'

export const EMAIL_REGEX = '^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$'
