import { Modal } from '~elements'
import { RecipientsModalHeader } from './RecipientsModalHeader'
import { RecipientsModalBody } from './RecipientsModalBody'
import styles from './index.module.scss'

interface RecipientsModalProps {
  fileName: string
  videoId: number
  isOpen: boolean
  handleModalClose: () => void
  shareUrl?: string
}

export const ManageRecipientsModal = ({ fileName, videoId, isOpen, handleModalClose, shareUrl }: RecipientsModalProps) => {
  return (
    <Modal isOpen={isOpen} isRecipientsModal={true} contentWidth={'narrow'} handleModalClose={handleModalClose}>
      <div className={styles['modal-content-container']}>
        <RecipientsModalHeader fileName={fileName} />
        <RecipientsModalBody fileName={fileName} videoId={videoId} shareUrl={shareUrl ? shareUrl : undefined} handleModalClose={handleModalClose} />
      </div>
    </Modal>
  )
}
