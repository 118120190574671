import { IVerifyRequestBody } from '~interfaces'

export const VerifyRecipient = async ({ email }: IVerifyRequestBody) => {
  try {
    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/verify`, {
      method: 'POST',
      body: JSON.stringify({
        email
      })
    })
    return await result.json()
  } catch (e) {
    throw new Error('Unable to verify email.')
  }
}
