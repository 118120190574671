import { IShareRequestBody } from '~interfaces'
import { getIdToken } from '~utils'

export const SharePreview = async ({
  allowedTimesOfDay,
  allowedVantagePoints,
  domains,
  emails,
  notificationMessage,
  fileName,
  sharerName,
  videoId
}: IShareRequestBody) => {
  try {
    const headers = new Headers()
    const id = getIdToken()
    headers.append('Authorization', `Bearer ${id}`)

    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/share`, {
      method: 'POST',
      body: JSON.stringify({
        allowedTimesOfDay,
        allowedVantagePoints,
        domains,
        emails,
        notificationMessage,
        fileName,
        sharerName,
        videoId
      }),
      headers
    })

    return await result.json()
  } catch (e) {
    throw new Error('Unable to share preview.')
  }
}
