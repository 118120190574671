import { useRouter } from 'next/navigation'
import styles from './index.module.scss'
import { LanguageFillIcon } from '~elements'
import { usePreviewStore } from '~store'
import { CreatePreviewURL } from '~services'
import { IPreviewVideo } from '~interfaces'
import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from 'react'
import { getCognitoUserSession, ICognitoUser } from '~utils'
import { gaDLPush } from 'hooks/gaDLpush'
import { GA_EVENT, GA_EVENT_TYPE, PAGE } from '~enums'
import { EXOSPHERE } from '~constants'

interface IPreviewFileProps {
  record: IPreviewVideo
  displayError: Dispatch<SetStateAction<string | null>>
}

export const PreviewSelectedFile = ({ record, displayError }: IPreviewFileProps) => {
  const router = useRouter()
  const { setSelectedVideo } = usePreviewStore()
  const [userSession, setUserSession] = useState<ICognitoUser | null>(null)

  useEffect(() => {
    const fetchUserSession = async () => {
      const cognitoUserSession = await getCognitoUserSession()
      if (cognitoUserSession) {
        setUserSession(cognitoUserSession)
      }
    }
    fetchUserSession()
  }, [])

  const handlePreviewClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    // Get preSigned url and then send user to the preview link.
    try {
      const preSignedUrl = await CreatePreviewURL(record.title)

      if (preSignedUrl) {
        const gaDLParams = {
          event: GA_EVENT.PREVIEW_LAUNCH,
          cognito_user_id: userSession?.userId,
          content_filename: record.title,
          page: PAGE.CONTENT_MGMT,
          event_date: `${Date.now()}`,
          venue_name: EXOSPHERE,
          event_type: GA_EVENT_TYPE.CLICK
        }
        gaDLPush(gaDLParams)
        setSelectedVideo({ ...record, url: preSignedUrl })
        router.push(`${process.env.NEXT_PUBLIC_DOMAIN_URL}/video?id=${record.id}`)
      }
    } catch (error) {
      console.error(error)
      displayError(`Failed to preview file ${record.title}`)
    }
  }

  return (
    <button className={styles['preview-button']} onClick={handlePreviewClick} data-testid="preview-button">
      <LanguageFillIcon />
      Preview
    </button>
  )
}
