import React from 'react'
import styles from './index.module.scss'
import { CopyAndShareButton, LinkIcon, SimpleShareButton } from '~elements'
import { IEmailItem, IRecipientsModalControlPanel, IShareRequestBody } from '~interfaces'
import { SharePreview } from '~services'
import { getCognitoUserInfo } from '~utils'

export const RecipientsModalControlPanel = ({
  emails,
  fileName,
  videoId,
  shareUrl,
  enableShareButton,
  handleModalClose
}: IRecipientsModalControlPanel) => {
  const getValidEmails = (emails: IEmailItem[]) => {
    return emails.flatMap(email => (email.isValid ? [email.email] : []))
  }

  const sendShareRequest = (validEmails: string[], cognitoUserName: string) => {
    const shareRequest: IShareRequestBody = {
      allowedTimesOfDay: [],
      allowedVantagePoints: [],
      fileName,
      emails: validEmails,
      videoId,
      sharerName: `${cognitoUserName}`
    }
    SharePreview(shareRequest)
  }

  const handleShare = async () => {
    try {
      const cognitoUserInfo = await getCognitoUserInfo()
      if (!cognitoUserInfo) {
        throw new Error('Unknown error in fetching user info before sharing.')
      }

      const validEmails = getValidEmails(emails)
      if (!validEmails.length) {
        throw new Error('No valid emails.')
      }

      sendShareRequest(validEmails, cognitoUserInfo.name)
      handleModalClose()
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        console.error(`Unable to share ${fileName}. Please try again later.`)
      }
    }
  }

  return (
    <div className={styles['modal-control-panel']}>
      {/* <div className={styles['access-section']}>
        <div className={styles['item']}>
          <span className={styles['name']}>Domain Access</span>
          <span className={styles['number']}>0/3</span>
        </div>
        <div className={styles['item']}>
          <span className={styles['name']}>Vantage Point</span>
          <span className={styles['number']}>32/32</span>
        </div>
        <div className={styles['item']}>
          <span className={styles['name']}>Times of Day</span>
          <span className={styles['number']}>3/3</span>
        </div>
      </div>
      <div className={styles['divider']}></div> */}
      <div className={styles['panel-buttons']}>
        <CopyAndShareButton
          displayText={'Copy link'}
          tooltipText={'Link Copied!'}
          styles={styles}
          shareTitle={`${fileName} | Exo Web Preview`}
          Icon={LinkIcon}
          url={shareUrl}
          useNavigatorShare={false}
        />
        <SimpleShareButton styles={styles} handleClick={handleShare} displayText={'Share'} displayIcon={false} disabled={!enableShareButton} />
      </div>
    </div>
  )
}
