const getCookie = (name: string) => {
  const cookieArr = document.cookie.split("; ");
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split("=");
    if (name === decodeURIComponent(cookiePair[0])) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

export const getHashedUserIdFromCookie = () => {
  const cookieValue = getCookie('epu');
  if (cookieValue) {
    const [type, hashedUserId] = cookieValue.split('|');
    if (type === 'r' && hashedUserId) {
      return hashedUserId;
    }
  }
  return null;
}