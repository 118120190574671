'use client'

import { useEffect, useState } from 'react'
import cx from 'classnames'

import { Heading, Kicker, HelpIcon, UploadFile } from '~elements'
import { IDashboardBlock, IPreviewVideo } from '~interfaces'
import { ListView } from '~elements'

import { PREVIEW_LOGIN_PATH } from '~constants'
import { GetVideos, Logout } from '~services'
import { useRouter } from 'next/navigation'

import styles from './index.module.scss'

export const Dashboard = ({ heading, kicker }: IDashboardBlock) => {
  const [videos, setVideos] = useState<IPreviewVideo[]>([])
  const [companies, setCompanies] = useState<Array<string>>([])
  const [helpButtonClasses, setHelpButtonClasses] = useState(cx([styles['help-cta']]))
  const router = useRouter()

  useEffect(() => {
    ;(async () => {
      const { data } = await GetVideos()
      setVideos([...data])
    })()
  }, [])

  useEffect(() => {
    const ninetyMinutes = 1000 * 60 * 90
    const timer = setTimeout(() => {
      Logout()
      router.replace(PREVIEW_LOGIN_PATH)
    }, ninetyMinutes)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (videos) {
      setCompanies([...new Set(videos.map(record => record.experience_id))])
    }
  }, [videos])

  const onFileChange = async (status: boolean) => {
    if (status) {
      const { data } = await GetVideos()
      setVideos([...data])
    }
  }

  return (
    <div className={styles['dashboard-container']}>
      <div className={styles['dashboard-area']}>
        <div className={styles['dashboard-top-content']}>
          {heading && (
            <div className={styles['heading-container']}>
              <Heading {...heading} fontSize="32px" lineHeight="38px" />
              <a
                className={helpButtonClasses}
                target="_blank"
                href={`${process.env.NEXT_PUBLIC_CODA_HELP_URL}`}
                rel="noreferrer"
                // Really don't like this solution, but since we need to get rid of the hover state on click...we have this :).
                // More than open for another CSS or JS solution.
                onClick={() => setHelpButtonClasses(styles['help-cta'])}
                onMouseOver={() => setHelpButtonClasses(cx([styles['help-cta'], styles['help-cta-hover']]))}
                onMouseOut={() => setHelpButtonClasses(styles['help-cta'])}
              >
                <HelpIcon />
                <span>Help</span>
              </a>
            </div>
          )}
          {kicker && <Kicker {...kicker} color="#C6C6C6" fontSize="16px" lineHeight="24px" marginTop="4px" paddingLeft="8px" />}
          <UploadFile onFileUpload={onFileChange} />
        </div>
        {companies.map(company => (
          <ListView
            key={company}
            companyName={company}
            onFileDelete={onFileChange}
            records={videos.filter(record => record.experience_id === company)}
          />
        ))}
      </div>
    </div>
  )
}
