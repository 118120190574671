export enum EXOSPHERE_THUMB_ICON {
  NORTH_EAST = 'north-east',
  NORTH = 'north',
  NORTH_WEST = 'north-west',
  WEST = 'west',
  TOP = 'top',
  EAST = 'east',
  SOUTH_EAST = 'south-east',
  SOUTH = 'south',
  SOUTH_WEST = 'south-west'
}

export enum FORGOT_FIELD_KEYS {
  CONFIRM_PASSWORD = 'confirmPassword',
  NEW_PASSWORD = 'newPassword'
}

export enum FORGOT_PASSWORD_ACTIONS {
  INIT = 'init',
  COGNITO_FAILURE = 'cognito_failure',
  CONFIRM = 'confirm',
  RESEND_CODE = 'resend-verification-code'
}

export enum FORGOT_PASSWORD_BTN_COLOR {
  WHITE = 'white',
  TURQUOISE = 'turquoise'
}

export enum LOCAL_STORAGE {
  EXO_REMEMBER_ME = 'exo-remember-me',
  USER_KEY = 'x-amz-user-token',
  EXO_PREVIEW = 'exo-preview-url',
  EXO_PREVIEW_FILE_NAME = 'exo-preview-file-name'
}

export enum LOGIN_ACTIONS {
  SUBMIT = 'submit',
  SET_NEW = 'set-new',
  SET_NEW_CONFIRM = 'set-new-confirm'
}

export enum PREVIEW_DISCONNECT_TYPE {
  BEFORE_UNLOAD_EVENT = 'disconnect/before_unload_event',
  IDLE_TIMEOUT = 'disconnect/idle_timeout',
  USER_PRESSED_EXIT_PREVIEW = 'disconnect/user_pressed_exit_preview'
}

export enum VALIDATION_ACTIONS {
  INVALID_INPUT = 'invalid_input'
}

export enum RESULT_MODAL_TYPE {
  WARNING = 1,
  INFO = 2,
  ERROR = 3,
  SUCCESS = 4,
  ACCESS_DENIED = 5
}

// Google Analytics
export enum GA_EVENT_TYPE {
  BUTTON_CLICK = 'button-click',
  PAGE_LOAD = 'page-load',
  CLICK = 'click'
}

export enum GA_EVENT {
  PREVIEW_LAUNCH = 'preview_launch'
}

export enum PAGE {
  HOME = 'homepage',
  CONTENT_MGMT = 'content_mgmt'
}

export enum UPLOAD_STATUSES {
  SUCCESS_KEY_S3_KEY_MISMATCH = '1001',
  FAILURE_DURING_S3_PUT = '1002',
  FILE_NOT_FOUND = '1003',
  INSERTED_INTO_FAILURE_QUEUE = '1004',
  SUCCESSFUL_INSERT = '1005',
  FAILURE_DURING_SQS = '1006'
}
